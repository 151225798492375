<script>
import AdminLayout from "@layouts/admin";
import FormFieldInput from "@components/form-field-input";

export default {
    name: "DealerDetail",
    components: {
        AdminLayout,
        FormFieldInput,
    },
    data() {
        return {
            dealer: {},
            dealerId: null,
        };
    },
    created() {
        this.dealerId = this.$route?.params?.dealerId;

        this.getData();
    },
    methods: {
        async getData() {
            try {
                const { dealer } = await this.$http.getById(
                    "dealer/details",
                    this.dealerId
                );
                console.log({ dealer });
                this.dealer = dealer;
            } catch (err) {
                this.$utility.showErrorMessage(err.message);
            }
        },
    },
};
</script>

<template lang="pug">
admin-layout
    .container.px-4.py-8.text-center.mx-auto
        h2.text-center.text-4xl.mb-8.res-h1.pb-4.border Dealer Details
        span.text-center
            p.text-green-600.text-3xl(v-if="dealer.isApproved") Approved
            p.text-red-600.text-3xl(v-else) Not Approved
        .grid.grid-rows-2.grid-flow-col.gap-4.res-col
            .row-span-3.ml-auto.res-row
                .flex.m-8
                    .text-2xl Name: {{ $filters.humanize(dealer?.User?.name) || 'Na' }}
                .flex.m-8
                    .text-2xl Phone: {{ dealer?.User?.phone }}
                .flex.m-8
                    .text-2xl PAN Number: {{ dealer?.panNumber }}
                .flex.m-8
                    .text-2xl State: {{ dealer.state }}
            .row-span-2.mr-auto.res-row
                .flex.m-8
                    .text-2xl Business Name: {{ $filters.humanize(dealer?.shopName) || 'Na' }}
                .flex.m-8
                    .text-2xl Email: {{ dealer?.User?.email }}
                .flex.m-8
                    .text-2xl GST Number: {{  dealer?.panNumber || 'Na'  }}
                .flex.m-8
                    .text-2xl Address: {{ dealer?.address || 'Na' }}
        span.text-center
            p.text-green-600.text-3xl(v-if="dealer.availableBalance") Available Balance ₹ {{ dealer.availableBalance || 'Na' }}
            p.text-red-600.text-3xl(v-else) No Balance Available. Please activate your account.
        .mx-auto.res-row.text-center
            .flex.m-8.justify-center(v-if="dealer.createdBy")
                .text-2xl Created By: {{ $filters.humanize(dealer.Dealer.shopName) || 'Na' }}
</template>
